import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { FaFacebookF, FaLinkedin } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <a href='https://www.linkedin.com/in/farid-cure/' target='_blank' rel='noopener noreferrer'>
            {/* <BsTwitter /> */}
            <FaLinkedin />
        </a>
        <a href='#' target='_blank' rel='noopener noreferrer'>
            <FaFacebookF />
        </a>
        <a href='#' target='_blank' rel='noopener noreferrer'>
            <BsInstagram />
        </a>
    </div>
  )
}

export default SocialMedia